<template>
  <div class="zhongzi_list">
    <van-nav-bar
      title="种子兑换列表"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="!nodata">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="(item,index) in list" :key="index" :title="item.title" is-link center @click="edit(index)">
          <template #label>
            <div>{{item.zhongzi}}种子 兑换{{item.shichang}}天 {{item.levelname}}</div>
          </template>
        </van-cell>
      </van-list>
    </div>
    <div v-if="nodata">
      <van-empty description="暂无内容" />
    </div>
    <div class="btn_add">
      <van-button block type="danger" round @click="add">添加</van-button>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "ZhongziList",
  data() {
    return {
      info: {},
      list: [],
      loading: false,
      finished: false,
      nodata: false,
      offset: 0,
      size: 30,
      index: -1,
    }
  },
  mounted() {
    console.log("5YHDQ");
  },
  activated() {
    
    this.updateList();
    let zhongzi = null;
    zhongzi = localStorage.getItem("zhongzigengxin") ? localStorage.getItem("zhongzigengxin") : '0';
    console.log(zhongzi);
    if (zhongzi == '1') {
      localStorage.removeItem("zhongzigengxin");
      this.offset = 0;
      this.onLoad();
    }
  },
  methods: {
    init() {
      console.log("ssss");
    },
    onLoad() {
      let that = null;
      that = this;
      if (that.offset == 0) {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '加载中...',
        });
      }
      that.loading = true;
      that.finished = false;
      that.nodata = false;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/member/get_zhongzi_list",
          that.$qs.stringify({
            offset: that.offset,
            size: that.size
          })
        )
        .then(res => {
          console.log(res);
          that.loading = false;
          that.finished = false;
          that.nodata = false;
          if(that.offset == 0) {
            Toast.clear();
          }
          if (res.data.code == 100000) {
            if (that.offset == 0) {
              that.list = res.data.data;
            }else {
              if (res.data.data) {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
            }
            if (res.data.data.length < that.size) {
              that.finished = true;
            }
            that.offset += res.data.data.length;
          }else {
            that.finished = true;
            if (that.offset == 0) {
              that.nodata = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          if(that.offset == 0) {
            Toast.clear();
          }
        })
    },
    // 点击修改种子兑换信息
    edit(index) {
      this.index = parseInt(index);
      let id = 0;
      id = this.list[index].id;
      this.$router.push({
        path: 'set_zhongzi?id='+id
      })
    },
    add() {
      this.$router.push({
        path: 'set_zhongzi'
      })
    },
    // 更新局部信息
    updateList() {
      if (this.index >= 0) {
        let id = 0;
        id = this.list[this.index].id;
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        });
        let that = null;
        that = this;
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/get_zhongzi_one",
            that.$qs.stringify({
              id: id
            })
          )
          .then(res => {
            Toast.clear();
            if (res.data.code == 100000) {
              that.info = res.data.data;
              that.list[that.index]['shichang'] = that.info.shichang;
              that.list[that.index]['zhongzi'] = that.info.zhongzi;
              that.list[that.index]['level'] = that.info.level;
              that.list[that.index]['levelname'] = that.info.levelname;
            }
            that.index = -1;
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    onClickLeft() {
      this.$router.back();
    },
  },
}
</script>
<style lang="less">
.zhongzi_list {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .van-cell {
    .van-cell__title {
      flex: 1;
      text-align: left;
    }
  }
  .btn_add {
    padding: 10px 16px;
    overflow: hidden;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
  }
}
</style>